<template>
  <div class="test">Test page</div>
</template>

<script>
export default {
  name: "Test",
  components: {},
};
</script>
